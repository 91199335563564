import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Markdown from "react-markdown"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContentRenderer from "../components/ContentRenderer"
import Footer from "../components/Footer"

const Services = ({ data }) => {
  const services = data.strapiTechnicalServices

  return (
    <Layout>
      <Seo title="Services" />
      <section className="nextier-services-hero">
        <div className="blue-line-container">
          <div className="container">
            <h1 className="nextier-services-hero__title">{services.Title}</h1>
            <div className="nextier-services-hero__subtitle">
              <Markdown children={services.Text} />
            </div>

            <Link to="/contact" className="primary-btn_alt btn-arrow">
              <span>Contact our service team</span>
            </Link>
            {/* <svg className="nextier-services-hero__arrow">
              <use xlink:href="/assets/images/sprite.svg#green-arrow"></use>
            </svg> */}
          </div>
        </div>
        <div
          className="nextier-services-hero__background"
          style={{
            backgroundImage: `url(${services.HeroImage.url})`,
          }}
        ></div>
      </section>

      <section className="services-block1">
        <div className="blue-line-container">
          <div className="container services-block1__inner">
            <div className="content-container">
              <h2 className="services-textblock__title">
                {services.productMatching}
              </h2>
              <div className="content-block">
                <div className="green-line"></div>
                <div className="services-textblock__text">
                  <Markdown children={services.productMatchingText} />
                  <Link to="/products" className="primary-btn btn-arrow">
                    <span>View our products</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="services-block1__image1"
          src={services.firstImage.url}
          alt={services.firstImage.alternativeText}
        />
        <img
          className="services-block1__image2"
          src={services.secondImage.url}
          alt={services.secondImage.alternativeText}
        />
      </section>

      <section className="services-block2">
        <div className="blue-line-container">
          <div className="container services-block2__inner">
            <div className="content-container">
              <h2 className="services-textblock__title">
                {services.consultants}
              </h2>
              <div className="content-block">
                <div className="green-line"></div>
                <div className="services-textblock__text">
                  <Markdown children={services.consultantsText} />
                  <Link to="/products" className="primary-btn btn-arrow">
                    <span>View our products</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services-block3">
        <div className="blue-line-container">
          <div className="container services-block3__inner">
            <div className="content-container">
              <h2 className="services-textblock__title">
                Education & Training
              </h2>
              <div className="content-block">
                <div className="green-line"></div>
                <div className="services-textblock__text">
                  <Markdown children={services.educationText} />
                  <Link to="/products" className="primary-btn btn-arrow">
                    <span>View our products</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <img
          className="services-block3__image1"
          src={services.thirdImage.url}
          alt={services.thirdImage.alternativeText}
        /> */}
      </section>

      {/* <section className="services-team">
        <div className="container">
          <div className="services-team-inner-container">
            <h2 className="services-team__title">
              {services.specialistBlock.Title}
            </h2>
            <div className="services-team__text">
              {services.specialistBlock.Text}
            </div>
            <Link to="/products" className="primary-btn_alt btn-arrow">
              <span>Meet the team</span>
            </Link>
          </div>
          <div className="services-team-grid">
            <div className="services-team__card">
              <img
                className="services-team__card-image"
                src="/assets/images/pexels-oliver-sjöström-931018.jpg"
              />
              <h5 className="services-team__card-title">Lorem Ipsum</h5>
              <h6 className="services-team__card-role">title / role</h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                vulputate vel lorem etiam faucibus.
              </p>
            </div>
            <div className="services-team__card">
              <img
                className="services-team__card-image"
                src="/assets/images/pexels-oliver-sjöström-931018.jpg"
              />
              <h5 className="services-team__card-title">Lorem Ipsum</h5>
              <h6 className="services-team__card-role">title / role</h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                vulputate vel lorem etiam faucibus.
              </p>
            </div>
            <div className="services-team__card">
              <img
                className="services-team__card-image"
                src="/assets/images/pexels-oliver-sjöström-931018.jpg"
              />
              <h5 className="services-team__card-title">Lorem Ipsum</h5>
              <h6 className="services-team__card-role">title / role</h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                vulputate vel lorem etiam faucibus.
              </p>
            </div>
          </div>
        </div>
        <img
          src="/assets/images/large-x.svg"
          className="services-team__background-image"
        />
      </section> */}
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiTechnicalServices {
      Text
      Title
      consultants
      consultantsText
      productMatching
      productMatchingText
      educationText
      firstImage {
        alternativeText
        url
      }
      secondImage {
        alternativeText
        url
      }
      thirdImage {
        alternativeText
        url
      }
      HeroImage {
        alternativeText
        url
      }
      specialistBlock {
        Text
        Title
      }
    }
  }
`

export default Services
